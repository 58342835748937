import moment from 'moment';
import _ from 'lodash';
import CryptoJS from 'crypto-js';
import { getCurrentDate } from './datetime';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import { userRunTypes } from 'habitual-analytics/constants/habitual-configs';
import { getParsedDateObj } from './timezone';

export const getEncryptSha256Hex = (keyString) => {
  return CryptoJS.SHA256(keyString).toString(CryptoJS.enc.Hex);
};

export const getDaysToExpiry = (expiryDate, targetDate) => {
  const targetDateMmt = targetDate
    ? getParsedDateObj(targetDate)
    : getCurrentDate().startOf('day');
  return getParsedDateObj(expiryDate).diff(
    targetDateMmt,
    'day' // 1
  );
};

export const nFormatter = (formatterNum, digits = 1) => {
  const num = Math.abs(formatterNum);

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    // { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((i) => {
      return num >= i.value;
    });

  const returnValue = item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';

  return formatterNum > 0 ? returnValue : `-${returnValue}`;
};

const instrumentTypes = {
  option: 'OPT',
  future: 'FUT',
  equity: 'EQ',
};

export const getInstrumentType = (instrument) => {
  if (_.endsWith(instrument, 'CE') || _.endsWith(instrument, 'PE')) {
    return instrumentTypes.option;
  }
  if (_.endsWith(instrument, 'FUT')) {
    return instrumentTypes.future;
  }

  return instrumentTypes.equity;
};

export const getInstrumentDetails = (tradingSymbol) => {
  const instrumentEndIndex = tradingSymbol?.search(/[0-9]/);
  const instrument = tradingSymbol.slice(0, instrumentEndIndex);
  const expiryDate = tradingSymbol?.slice(
    instrumentEndIndex,
    instrumentEndIndex + 7
  );

  const expiryYear = expiryDate?.slice(5);
  const formattedExpiryYear = _.parseInt(
    moment(expiryYear, 'YY').format('YYYY')
  );
  const expiryMonthSymbol = expiryDate?.slice(2, 5);
  const expiryDateNumber = expiryDate?.slice(0, 2);

  return {
    expiryYear,
    expiryMonthSymbol,
    expiryDateNumber,
    formattedExpiryYear,
    expiryDate,
    instrument,
  };
};

export const refreshPage = () => {
  window.location.reload();
};

export const isLiveMode = () => {
  return (
    getDynamicAppConfigs()?.historicalConfigs?.userRunType === userRunTypes.live
  );
};

export const isSupportedExchangeSegment = (exchangeSegment) => {
  const { exchangeSegmentSupport } = getDynamicAppConfigs();
  return exchangeSegmentSupport?.[
    `is${_.upperFirst(_.toLower(exchangeSegment))}`
  ];
};

export const isPaperTrade = () => {
  return getDynamicAppConfigs()?.isPaperTrade === true;
};
