import { markets } from 'zeequant-constants';
import { getDynamicAppConfigs } from '../constants/dynamicAppConfigs';
import { avoidedInstruments } from '../constants/habitual-configs';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import { DATE_FORMAT } from '../dateUtils/dateFormats';

// Reference markets.md in zeequant-constants
const weekly_to_monthly = ['BANKNIFTY', 'MIDCPNIFTY', 'BANKEX', 'FINNIFTY'];

const MarketUtility = (() => {
  const { MARKET } = window?.instaConstants || {};

  let currentMarketConfig = markets[MARKET] || {};

  const getConstantExpiryType = ({ symbol, expiryType }) => {
    if (_.includes(weekly_to_monthly, symbol)) {
      return 'monthly';
    }

    return expiryType;
  };

  const instrumentFilterFn = (configs) => {
    const { isBseEnabled, bseEnabledInstruments } =
      getDynamicAppConfigs()?.domainConfigs;

    if (isBseEnabled) {
      return _.chain(configs)
        .filter(({ symbol }) => !_.includes(avoidedInstruments, symbol))
        .sortBy(({ isIndex }) => !isIndex)
        .value();
    }

    return _.chain(configs)
      .filter(({ symbol }) => !_.includes(bseEnabledInstruments, symbol))
      .filter(({ symbol }) => !_.includes(avoidedInstruments, symbol))
      .value();
  };

  return {
    init: (marketName) => {
      currentMarketConfig = markets[marketName] || {};
    },
    getSymbols: () => {
      return currentMarketConfig?.utility.getSymbols({
        filterFn: instrumentFilterFn,
      });
    },
    getIndexSymbols: () => {
      return currentMarketConfig?.utility.getSymbols({
        isIndex: true,
        filterFn: instrumentFilterFn,
      });
    },
    getIsinCodeBySymbol: (symbol) => {
      const getSymbolConfig = currentMarketConfig?.utility.getSymbolConfig({
        symbol,
      });

      return getSymbolConfig?.isinCode || '';
    },
    getAllIsinCodesWithSymbols: () => {
      const symbols = MarketUtility.getSymbols();
      return _.reduce(symbols, (result, { symbol, isinCode }) => {
        result[symbol] = isinCode;
        return result;
      });
    },
    getAllIsinCodes: () => {
      const symbols = MarketUtility.getAllIsinCodesWithSymbols();
      return _.values(symbols);
    },
    getSymbolByIsinCode: (isinCode) => {
      const symbols = MarketUtility.getAllIsinCodesWithSymbols();
      return _.findKey(symbols, (value) => value === isinCode) || '';
    },
    getValidMarketDate: (date) => {
      return currentMarketConfig?.utility.getValidMarketDate({
        date,
      });
    },
    getWeekendWorkingDates: () => {
      return currentMarketConfig?.weekendWorkingDates || [];
    },
    getHolidayDates: () => {
      return currentMarketConfig?.holidayDates || [];
    },
    getExpiryDates: currentMarketConfig?.utility.getExpiryDates,
    getExpiredExpiryDates: ({
      symbol,
      expiryType = '*',
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return MarketUtility.getExpiryDates({
        symbol,
        expiryType: getConstantExpiryType({ symbol, expiryType }),
        filterFn: (data) => data.filter((d) => d < compareDate),
        uniqueValue: compareDate,
      });
    },
    getUpComingExpiryDates: ({
      symbol,
      expiryType = '*',
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return MarketUtility.getExpiryDates({
        symbol,
        expiryType: getConstantExpiryType({ symbol, expiryType }),
        filterFn: (data) => data.filter((d) => d >= compareDate),
        uniqueValue: compareDate,
      });
    },
    getMonthlyExpiryDates: ({ symbol }) => {
      return MarketUtility.getExpiryDates({
        symbol,
        expiryType: 'monthly',
      });
    },
    getCurrentExpiryDate: ({
      symbol,
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          compareDate,
          expiryType: getConstantExpiryType({ symbol, expiryType: '*' }),
        })
      )
        .first()
        .value();
    },
    getCurrentMonthExpiryDate: ({
      symbol,
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          compareDate,
          expiryType: 'monthly',
        })
      )
        .filter((expiryDate) => expiryDate >= compareDate)
        .first()
        .value();
    },
    getNextMonthExpiryDate: ({
      symbol,
      compareDate = getCurrentDate().format(DATE_FORMAT),
    }) => {
      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          compareDate,
          expiryType: 'monthly',
        })
      )
        .filter((expiryDate) => expiryDate >= compareDate)
        .nth(1)
        .value();
    },
    getCurrentYearExpiryDates: ({ symbol }) => {
      const currentYear = getCurrentDate().year();
      return _.chain(
        MarketUtility.getUpComingExpiryDates({
          symbol,
          expiryType: 'monthly',
        })
      )
        .filter((expiryDate) => expiryDate.slice(0, 4) === currentYear)
        .value();
    },
    getMonthBasedExpiryDates: ({ symbol = '', month, expiryDates = [] }) => {
      const currentYear = getCurrentDate().year();
      const referenceYearMonth = `${currentYear}-${month}`;
      return _.chain(
        _.isEmpty(expiryDates)
          ? MarketUtility.getMonthlyExpiryDates({ symbol })
          : expiryDates
      )
        .filter((expiryDate) => expiryDate.slice(0, 7) === referenceYearMonth)
        .value();
    },
    getSymbolConfig: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const expiryType = MarketUtility.getExpiryType({ symbol, date });
      const symbolConfig = currentMarketConfig?.utility.getSymbolConfig({
        symbol,
        date,
        expiryTypes: [expiryType],
      });

      return symbolConfig;
    },
    getSymbolLotSize: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const symbolConfig = MarketUtility.getSymbolConfig({ symbol, date });
      return _.get(symbolConfig, 'attributes.lotSize', 1);
    },
    getSymbolStrikeInterval: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const symbolConfig = MarketUtility.getSymbolConfig({ symbol, date });
      return _.get(symbolConfig, 'attributes.strikeInterval', 1);
    },
    getSymbolFreezeQuantity: ({
      symbol,
      date = MarketUtility.getCurrentExpiryDate({ symbol }),
    }) => {
      const symbolConfig = MarketUtility.getSymbolConfig({ symbol, date });
      return _.get(symbolConfig, 'attributes.freezeQuantity', 0);
    },
    getExpiryType: ({ symbol, date }) => {
      const monthlyExpiryDates = MarketUtility.getMonthlyExpiryDates({
        symbol,
      });
      return _.includes(monthlyExpiryDates, date) ? 'monthly' : 'weekly';
    },
    isOnlyMonth: ({ symbol }) => {
      return _.chain(MarketUtility.getIndexSymbols())
        .without(...weekly_to_monthly)
        .thru((indexSymbols) =>
          _.includes(indexSymbols, symbol) ? false : true
        )
        .value();
    },
    getValidExpiryDate: ({ symbol, expiryDate = '' }) => {
      const expiryType = MarketUtility.isOnlyMonth({
        symbol,
      }) ? 'monthly' : 'weekly';
      const isValidExpiryDate = _.includes(
        MarketUtility.getExpiryDates({
          symbol,
          expiryType,
        }),
        expiryDate
      );

      return isValidExpiryDate
        ? expiryDate
        : MarketUtility.getUpComingExpiryDates({ symbol, expiryType })[0];
    },
    getDropDownListExpiryDates: ({ symbol, shouldIncludeWeekly = true }) => {
      const expiryType = MarketUtility.isOnlyMonth({ symbol }) ? 'monthly' : 'weekly';
      const weeklyOrMonthlyExpiryDates = MarketUtility.getUpComingExpiryDates({
        symbol,
        expiryType,
      });
      const monthlyExpiryDates = MarketUtility.getUpComingExpiryDates({
        symbol,
        expiryType: 'monthly',
      });

      return _.chain(weeklyOrMonthlyExpiryDates)
        .thru((expiryDates) => {
          if (!shouldIncludeWeekly) {
            return [];
          }
          return expiryDates.concat(monthlyExpiryDates);
        })
        .slice(0, expiryType === 'weekly' ? 5 : 2)
        .concat(_.slice(monthlyExpiryDates, 0, 2))
        .uniq()
        .sortBy()
        .value();
    },
  };
})();

export default MarketUtility;
