import { getAxisDirectScriptIds } from 'habitual-analytics/api/services/getExchangeToken';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isValidSymbolForInsta,
  isOrderPartiallyExecuted,
  parseExchangeTokenTradingSymbol,
} from '../utils';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { displayInstrumentToSATradingSymbol } from 'habitual-analytics/models/instrument/factory';
import { getParsedDateObj } from 'habitual-analytics/utils/timezone';
import _ from 'lodash';
import { PRETTIER_FORMAT_WITH_SECONDS } from 'habitual-analytics/dateUtils/dateFormats';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import { getDefaultProductCode } from '../tradingSymbolParser';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import { parseTradingSymbol } from './workmate';

const parseProductCode = (productCode) => {
  let pCode;
  switch (productCode) {
    case 'mis':
      pCode = 'INTRADAY';
      break;
    case 'cnc':
    case 'nrml':
      pCode = 'DELIVERY';
      break;
    default:
      pCode = '';
      break;
  }
  return pCode;
};

const parseProductType = (productType) => {
  let pType;
  switch (productType) {
    case 'l':
      pType = 'RL';
      break;
    case 'mkt':
      pType = 'RL-MKT';
      break;
    case 'sl':
      pType = 'SL';
      break;
    case 'sl-m':
      pType = 'SL-MKT';
      break;
    default:
      pType = '';
      break;
  }
  return pType;
};

const sanitizeAndParseOrderStatus = (orderStatus, orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderStatus)) {
    case 'cancelled':
      status = statusConfigs.cancelled.value;
      break;
    case 'executed':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail?.orderStatus,
      orderDetail?.totalQty,
      orderDetail?.tradedQty
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const transactionType = _.toLower(_.get(orderDetail, 'transactionType', ''));
  const tradingSymbol = _.get(orderDetail, 'symbol', '').split(' ').join('');
  const tradingSymbolObj = displayInstrumentToSATradingSymbol(tradingSymbol);
  const tradedTime = getParsedDateObj(_.get(orderDetail, 'omsOrderTS', ''));
  const status = sanitizeAndParseOrderStatus(
    _.get(orderDetail, 'orderStatus', '')
  );
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'errorReason', '');
  const productCode = _.get(orderDetail, 'product', '');
  const productType = _.get(orderDetail, 'orderType', '');
  const tradedQuantity = _.get(orderDetail, 'tradedQty', 0);
  const quantity = _.get(orderDetail, 'totalQty', '');
  const price = _.get(orderDetail, 'orderPrice', 0);
  const targetPrice = _.get(orderDetail, 'triggerPrice', 0);
  const tradedPrice =
    _.parseInt(targetPrice) !== 0 ? `${price} / ${targetPrice} trg` : price;

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    type:
      transactionType === transactionTypes.buy.value
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    time: tradedTime.format(PRETTIER_FORMAT_WITH_SECONDS),
    status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      orderNo: _.get(orderDetail, 'omsOrderId', ''),
      tradedPrice: tradedPrice,
      order: orderDetail,
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }
  const tradingSymbolObj = parseTradingSymbol(orderDetail);
  const transactionType = _.toLower(_.get(orderDetail, 'transactionType', ''));
  const status = statusConfigs.executed.label;
  const tradedQuantity = Number(_.get(orderDetail, 'tradeQty', 0));
  const quantity = Number(_.get(orderDetail, 'tradeQty', 0));
  const tradedTime = getParsedDateObj(_.get(orderDetail, 'tradeTS', ''));

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    type:
      transactionType === transactionTypes.buy.value
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    time: tradedTime.format(PRETTIER_FORMAT_WITH_SECONDS),
    status,
    extraDetails: {
      product: _.get(orderDetail, 'product', ''),
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'tradePrice', 0)),
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', 'NSE');
  const instrument = _.get(orderDetail, 'instrument', '');
  const tradingSymbol = instrument === 'Equity'
    ? _.slice(_.get(orderDetail, 'symbol', ''), 0, -2).join('')
    : _.get(orderDetail, 'securityDescription', '').split(' ').join('');
  const tradingSymbolObj = displayInstrumentToSATradingSymbol(tradingSymbol);

  if (
    !tradingSymbolObj || !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
    || !isValidSymbolForInsta(exchange, tradingSymbolObj.toString())
  ) {
    return null;
  }

  const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
  const qty = Number(_.get(orderDetail, 'netQty', 0));
  const buyAvg = _.get(orderDetail, 'avgBuyPrice', 0);
  const sellAvg = _.get(orderDetail, 'avgSellPrice', 0);
  const realisedprofitloss = _.round(_.get(orderDetail, 'realizedPL', 0), 2);
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const currentProfitLoss =
    type === transactionTypes.buy.value ? ltp - buyAvg : sellAvg - ltp;
  const profitLoss =
    qty === 0
      ? realisedprofitloss
      : _.round(currentProfitLoss, 2) * Math.abs(qty) + realisedprofitloss;

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'product', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'securityInfo[0][exchange]', '');

  if (!isValidSymbolForInsta(exchange)) {
    return null;
  }

  const tradingSymbol = _.get(orderDetail, 'securityInfo[0][symbol]', '');

  const tradingSymbolObj = displayInstrumentToSATradingSymbol(tradingSymbol);

  const quantity = _.parseInt(_.get(orderDetail, 'holdingQty', 0));
  const orderValue = convertToNumber(_.get(orderDetail, 'avgPrice', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'ltp', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbol,
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
    },
  };
};

const parsePlaceOrder = async (orderConfig) => {
  const tradingSymbolObj = orderConfig?.tradingSymbolObj;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const scriptIds = await getAxisDirectScriptIds([
    formattedExchangeTokenTradingSymbol,
  ]);
  return {
    segment: tradingSymbolObj?.isEquity() ? 'EQ' : 'FO',
    exchange: tradingSymbolObj?.isEquity() ? 'NSE_EQ' : 'NSE_FO',
    transactionType: _.toUpper(orderConfig?.transactionType),
    productType: parseProductCode(_.toLower(orderConfig?.pCode)),
    orderType: parseProductType(_.toLower(orderConfig?.prctyp)),
    validityType: _.toUpper(orderConfig?.ret),
    isAmo: false,
    totalQty: _.parseInt(orderConfig?.qty),
    disclosedQty: _.parseInt(orderConfig?.disCloseQty),
    orderPrice: parseFloat(orderConfig?.price),
    triggerPrice: parseFloat(orderConfig?.trigPrice),
    validityDays: 1,
    scriptId: _.toString(scriptIds?.[formattedExchangeTokenTradingSymbol]),
    orderRefId: 'instaoptions',
  };
};

const parseCancelOrder = (orderDetail) => {
  const {
    tradingSymbolObj,
    extraDetails: { order, product, qty },
  } = orderDetail;
  const qtyDetails = qty.split('/');
  const productDetails = product.split('/');

  return {
    exchange: tradingSymbolObj?.isEquity() ? 'NSE_EQ' : 'NSE_FO',
    transactionType: _.toUpper(orderDetail?.type),
    segment: tradingSymbolObj?.isEquity() ? 'EQ' : 'FO',
    validityType: _.toUpper(order?.validityType),
    omsOrderId: orderDetail?.extraDetails?.orderNo,
    orderPrice: _.parseInt(orderDetail?.extraDetails?.tradedPrice),
    openQty: _.parseInt(_.trim(_.get(qtyDetails, '1', ''))),
    productType: _.trim(_.get(productDetails, '0', '')),
    orderType: _.trim(_.get(productDetails, '1', '')),
    omsOrderSerialNumber: '1',
    scriptId: _.get(order, 'scriptId', ''),
    validity: 'DAY',
    symbol: tradingSymbolObj?.toString(),
    triggerPrice: _.parseInt(_.get(order, 'triggerPrice', 0)),
  };
};

const parseOrderDetails = (orders, type) => {
  let formattedData = [];
  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parseRequiredMarginCalculator = async (orderConfig) => {
  const tradingSymbolObj = _.get(orderConfig, 'tradingSymbolObj', '');
  const formattedExchangeTokenTradingSymbol = parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const scriptIds = await getAxisDirectScriptIds([formattedExchangeTokenTradingSymbol]);

  return {
    scriptId: _.toString(scriptIds?.[formattedExchangeTokenTradingSymbol]),
    segment: tradingSymbolObj?.isEquity() ? 'EQ' : 'FO',
    orderPrice: parseFloat(orderConfig?.price),
    orderQuantity: _.parseInt(orderConfig?.qty),
    transactionType: _.toUpper(orderConfig?.transactionType),
    productType: parseProductCode(_.toLower(orderConfig?.pCode)),
    triggerPrice: parseFloat(orderConfig?.trigPrice),
  };
};

export {
  parsePlaceOrder,
  parseOrderDetails,
  parseOrderBook,
  parseTradeBook,
  parseHoldingsBook,
  parseCancelOrder,
  parsePositionBook,
  parseRequiredMarginCalculator,
};
