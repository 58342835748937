import {
  equitySymbolToSATradingSymbol,
  withoutMonthOrMonthNumberFormatSymbolToSATradingSymbol,
} from 'habitual-analytics/models/instrument/factory';
import _ from 'lodash';
import {
  getInstrumentDetails,
  getInstrumentType,
} from 'habitual-analytics/utils/index';
import moment from 'moment';
import {
  BROKER_CONFIG,
  IndexToMonthNames,
  statusConfigs,
} from 'habitual-analytics/constants/habitual-configs';
import { fetchBrokerConfigs, facadeBrokers } from 'habitual-analytics/brokers/index';
import { getDynamicAppConfigs, initDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';
import {
  getInstrumentDetailsByExchangeTokens
} from 'habitual-analytics/api/services/getInstrumentDetailsByExchangeTokens';
import Instrument from 'habitual-analytics/models/instrument/index';
import MarketUtility from '../utils/marketUtility';

export const convertToNumber = (value) => {
  return parseFloat(_.replace(value, ',', ''));
};

export const exchangeSegement = (tradingSymbolObj) => {
  if (tradingSymbolObj.isEquity() && tradingSymbolObj.getExchange() === 'NSE') {
    return 'NSE';
  } else if (tradingSymbolObj.getExchange() === 'BSE') {
    return 'BFO';
  } else {
    return 'NFO';
  }
};

export const isValidSymbolForInsta = (exchange, tradingSymbol = '') => {
  const lowerCaseExchange = _.toLower(exchange);
  const isNseAllowed = _.includes(
    ['nse', 'nsecm', 'nsefo', 'nfo', 'nse_fo', 'nse_eq'],
    lowerCaseExchange
  );
  const { isBseEnabled, bseEnabledInstruments, broker } =
    getDynamicAppConfigs()?.domainConfigs;

  const isFuture = _.endsWith(tradingSymbol, 'FUT');

  const futureSymbolChecker = () => {
    if (
      [BROKER_CONFIG.tradesmart.id].includes(broker)
    ) {
      return _.isNaN(Number(tradingSymbol?.slice(-5, -3)));
    }
    return true;
  };

  const isBseAllowed =
    isBseEnabled &&
    _.includes(
      ['bfo', 'bsefo', 'bse', 'bse_fo', 'bse-eq'],
      lowerCaseExchange
    ) &&
    _.some(bseEnabledInstruments, (bseSymbol) =>
      _.includes(tradingSymbol, bseSymbol)
    ) &&
    (!isFuture || (isFuture && futureSymbolChecker())); // must clean up
  // SENSEX24JULFUT -> Permitted
  // SENSEX24705FUT -> Not Permitted // weekly expiry

  return isNseAllowed || isBseAllowed;
};

export const isFinNifty = (symbol) => symbol === 'FINNIFTY';
export const isSensex = (symbol) => symbol === 'SENSEX';
export const isBankex = (symbol) => symbol === 'BANKEX';
export const isBankNifty = (symbol) => symbol === 'BANKNIFTY';
export const isMidcpNifty = (symbol) => symbol === 'MIDCPNIFTY';

export const getTradingSymbolObj = (tradingSymbol) => {
  const instrumentType = getInstrumentType(tradingSymbol);

  return {
    tradingSymbolObj:
      _.endsWith(tradingSymbol, 'EQ') ||
        (instrumentType !== 'FUT' && instrumentType !== 'OPT')
        ? equitySymbolToSATradingSymbol(tradingSymbol)
        : withoutMonthOrMonthNumberFormatSymbolToSATradingSymbol(tradingSymbol),
  };
};

export const getCancellableOrderStatus = (status) => {
  return !_.includes(
    [
      statusConfigs?.cancelled?.value,
      statusConfigs?.executed?.value,
      statusConfigs?.failed?.value,
    ],
    _.toLower(status)
  );
};

export const isOrderPartiallyExecuted = (status, orderedQty, tradedQty) => {
  return (
    _.includes(
      [
        statusConfigs?.cancelled?.value,
        statusConfigs?.executed?.value,
        statusConfigs?.placed?.value,
      ],
      status
    ) &&
    Number(tradedQty) > 0 &&
    Number(tradedQty) !== Number(orderedQty)
  );
};

export const parseExchangeTokenTradingSymbol = (tradingSymbolObj) => {
  let exchangeTokenTradingSymbol;

  if (tradingSymbolObj.isEquity()) {
    exchangeTokenTradingSymbol = tradingSymbolObj.toString();
  } else if (tradingSymbolObj.isFuture()) {
    const instrumentEndIndex = tradingSymbolObj.toString()?.search(/[0-9]/);
    const instrument = tradingSymbolObj
      .toString()
      .substring(0, instrumentEndIndex);
    const expiryYear = tradingSymbolObj
      .toString()
      .substring(_.size(instrument) + 5, _.size(instrument) + 7);
    const expiryMonthName = tradingSymbolObj
      .toString()
      .substring(_.size(instrument) + 2, _.size(instrument) + 5);

    exchangeTokenTradingSymbol = `${instrument}${expiryYear}${expiryMonthName}FUT`;
  } else {
    const { expiryYear, expiryMonthSymbol, expiryDateNumber, instrument } =
      getInstrumentDetails(tradingSymbolObj.toString());
    const { strikePrice, optionType } = tradingSymbolObj.getSymbolObject();
    const expiryType = MarketUtility.getExpiryType({
      symbol: tradingSymbolObj.symbol,
      date: tradingSymbolObj.expiryDate,
    });

    if (expiryType === 'weekly') {
      const expiryMonthNumber = moment(expiryMonthSymbol, 'MMM').format('M');
      const expiryMonthNumberFormat =
        expiryMonthNumber > 9
          ? _.first(IndexToMonthNames[expiryMonthNumber])
          : expiryMonthNumber;
      exchangeTokenTradingSymbol = `${instrument}${expiryYear}`;
      exchangeTokenTradingSymbol += `${expiryMonthNumberFormat}${expiryDateNumber}`;
      exchangeTokenTradingSymbol += `${strikePrice}${optionType}`;
    } else {
      exchangeTokenTradingSymbol = `${instrument}${expiryYear}`;
      exchangeTokenTradingSymbol += `${expiryMonthSymbol}${strikePrice}${optionType}`;
    }
  }
  return exchangeTokenTradingSymbol;
};

export const getGenericProductAndOrderType = (extraDetails, isOrderBook) => {
  const { broker } = getDynamicAppConfigs().brokerConfigs;
  const productDetails = _.get(extraDetails, 'product', '').split('/').map(_.trim);
  if (_.isEmpty(productDetails)) return '';
  let productType = '';
  let productCode = '';
  isOrderBook === true ? [productType, productCode] = productDetails
    : [productCode, productType] = productDetails;
  const customProductCode = facadeBrokers.includes(broker)
    ? _.get(
      fetchBrokerConfigs('getLabelConfigs')?.booksLabelConfigs || {},
      _.toLower(productCode),
      _.toUpper(productCode)
    )
    : productCode;

  if (productType && customProductCode) return `${productType} / ${customProductCode}`;
  return productType || customProductCode || '';
};

export const parseInstrumentDetailsByExchangeTokens = async (exchangeTokens) => {
  const instrumentDetailsByExchangeTokens = _.get(getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens', {}
  );
  const missingTokens = _.filter(exchangeTokens, (exchangeToken) =>
    _.isEmpty(instrumentDetailsByExchangeTokens[exchangeToken])
  );
  if (missingTokens.length > 0) {
    const fetchedDetails = await getInstrumentDetailsByExchangeTokens(missingTokens);
    const formattedFetchDetails = _.keyBy(fetchedDetails, 'exchange_token');
    const mergedDetails = _.merge({}, instrumentDetailsByExchangeTokens, formattedFetchDetails);
    initDynamicAppConfigs({
      instrumentDetailsByExchangeTokens: mergedDetails,
    });
  }
};

export const parseTradingSymbolObjByExchangeToken = (exchangeToken) => {
  const instrumentDetailsByExchangeTokens = _.get(getDynamicAppConfigs(),
    'instrumentDetailsByExchangeTokens', {}
  );
  const fetchedDetails = _.find(instrumentDetailsByExchangeTokens,
    (_, key) => key == exchangeToken
  );
  
  if (_.isEmpty(fetchedDetails)) return null;

  if (fetchedDetails?.type === 'EQ') {
    return new Instrument({
      symbol: fetchedDetails?.tradingsymbol,
    });
  } else if (_.includes(['BFO-OPT', 'NFO-OPT'], fetchedDetails?.segment)) {
    return new Instrument({
      symbol: fetchedDetails?.symbol,
      expiryDate: fetchedDetails?.expiryDate,
      strikePrice: fetchedDetails?.strikePrice,
      optionType: fetchedDetails?.type,
    });
  } else {
    return new Instrument({
      symbol: fetchedDetails?.symbol,
      expiryDate: fetchedDetails?.expiryDate,
    });
  }
};
