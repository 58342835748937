import moment from 'moment';
import _ from 'lodash';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import { isCurrentTimeWithinTradingHours } from 'habitual-analytics/utils/datetime';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isOrderPartiallyExecuted,
  isValidSymbolForInsta,
  parseExchangeTokenTradingSymbol,
  parseInstrumentDetailsByExchangeTokens,
  parseTradingSymbolObjByExchangeToken,
} from '../utils';
import {
  getDefaultProductCode,
  getPlaceOrderTradingSymbol,
} from '../tradingSymbolParser';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';

const parseOrderType = (orderType) => {
  switch (orderType) {
    case 'Limit':
    case 'L':
      return 'LIMIT';
    case 'MKT':
      return 'MARKET';
    case 'SL':
      return 'SL';
    case 'SL-M':
      return 'SLM';
    default:
      return orderType;
  }
};

const defaultParseOrderType = (orderType) => {
  let type;
  switch (_.toLower(orderType)) {
    case 'limit':
    case 'l':
      type = 'L';
      break;
    case 'market':
      type = 'MARKET';
      break;
    case 'sl':
      type = 'SL';
      break;
    case 'slm':
      type = 'SL-M';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pcode) => {
  let productCode;
  switch (_.toLower(pcode)) {
    case 'cnc':
      productCode = 'CNC';
      break;
    case 'mis':
      productCode = 'MIS';
      break;
    case 'nrml':
      productCode = 'NRML';
      break;
    default:
      productCode = '';
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let status = statusConfigs.placed.value;

  switch (_.lowerCase(orderDetail.order_status)) {
    case 'cancel_confirmed':
    case 'cancel confirmed':
      status = statusConfigs.cancelled.value;
      break;
    case 'complete':
      status = statusConfigs.executed.value;
      break;
    case 'rejected':
      status = statusConfigs.failed.value;
      break;
    case isOrderPartiallyExecuted(
      orderDetail.status,
      orderDetail.qty,
      orderDetail.fillshares
    ):
      status = statusConfigs.partiallyExecuted.value;
      break;
    default:
      status = statusConfigs.placed.value;
      break;
  }

  return status;
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(_.get(orderDetail, 'instrument_token', ''));

  if (
    !tradingSymbolObj || !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
    || !isValidSymbolForInsta(exchange, tradingSymbolObj.toString())
  ) {
    return null;
  }

  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product', '');
  const targetPrice = convertToNumber(_.get(orderDetail, 'trigger_price', 0));
  const price = convertToNumber(_.get(orderDetail, 'price', 0));
  const tradedQty = _.get(orderDetail, 'filled_quantity', 0);
  const quantity = Number(_.get(orderDetail, 'quantity', 0));
  const avgPrice = convertToNumber(
    _.get(orderDetail, 'average_trade_price', 0)
  );
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice.toFixed(2)
        : price.toFixed(2);
  const tradedTime = moment(_.get(orderDetail, 'order_entry_time', '') * 1000)
    .utcOffset('+05:30')
    .format(TIME_FORMAT);
  const status = sanitizeAndParseOrderStatus(orderDetail);
  const isCancellableOrder = getCancellableOrderStatus(status);
  const failedReason = _.get(orderDetail, 'rejection_reason', '');

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'order_side', '') === 'SELL'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${Math.abs(tradedQty)} / ${Math.abs(quantity)}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'oms_order_id', ''),
      defaultProductType: defaultParseOrderType(
        _.get(orderDetail, 'order_type', '')
      ),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
      orderDetail,
    },
  };
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const exchangeToken = _.get(orderDetail, 'instrument_token', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(exchangeToken);

  if (
    !tradingSymbolObj || !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
    || !isValidSymbolForInsta(exchange, tradingSymbolObj.toString())
  ) {
    return null;
  }
  const productType = _.get(orderDetail, 'order_type', '');
  const productCode = _.get(orderDetail, 'product', '');
  const tradedQuantity = Number(_.get(orderDetail, 'trade_quantity', 0));
  const quantity = Number(_.get(orderDetail, 'filled_quantity', 0));
  const status = statusConfigs.executed.label;
  const tradedTime = moment(_.get(orderDetail, 'order_entry_time', '') * 1000)
    .utcOffset('+05:30')
    .format(TIME_FORMAT);

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'order_side', '') === 'SELL'
        ? transactionTypes?.sell?.value
        : transactionTypes?.buy?.value,
    status,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'trade_price', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const exchangeTokens = _.get(orderDetail, 'instrument_token', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(exchangeTokens);

  if (
    !tradingSymbolObj || !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
    || !isValidSymbolForInsta(exchange, tradingSymbolObj.toString())
  ) {
    return null;
  }

  const ltp = convertToNumber(_.get(orderDetail, 'ltp'));
  const qty = Number(_.get(orderDetail, 'net_quantity', 0));
  const buyAvgPrice = _.get(orderDetail, 'average_buy_price', 0);
  const sellAvgPrice = _.get(orderDetail, 'average_sell_price', 0);

  const realisedProfitLoss = _.get(orderDetail, 'realized_mtm', 0);
  const unRealisedProfitLoss =
    qty === 0
      ? 0
      : qty > 0
        ? (ltp - buyAvgPrice) * qty
        : (ltp - sellAvgPrice) * qty;

  const buyAvg = _.round(convertToNumber(buyAvgPrice), 2);
  const sellAvg = _.round(convertToNumber(sellAvgPrice), 2);

  const transactionTypes = { buy: { value: 'buy' }, sell: { value: 'sell' } };
  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const profitLoss = realisedProfitLoss + unRealisedProfitLoss;

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    extraDetails: {
      product: _.get(orderDetail, 'product', ''),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
      symbol: tradingSymbolObj.toString(),
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }

  return parseSubPositionBook(orderDetail);
};

const parseSubHoldingBook = (orderDetail) => {
  const exchangeTokens = _.get(orderDetail, 'token', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(exchangeTokens);

  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  };

  const quantity = _.get(orderDetail, 'quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'buy_avg', 0));
  const ltp = convertToNumber(_.get(orderDetail, 'ltp', 0));
  const profitLoss = getRoundedData(
    (_.replace(ltp, ',', '') - _.replace(orderValue, ',', '')) * quantity
  );
  const netChg = getRoundedData((profitLoss / orderValue) * 100);


  if (!_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)) {
    return null;
  }

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbolObj.toString(),
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parsePlaceOrder = async (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const exchangeSegement =
    tradingSymbolObj?.isEquity() && tradingSymbolObj?.getExchange() === 'NSE'
      ? 'NSE'
      : tradingSymbolObj?.getExchange() === 'BSE'
        ? 'BFO'
        : 'NFO';
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const exchange = tradingSymbolObj?.getExchange();
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol, exchange);

  return {
    exchange: exchangeSegement,
    order_type: parseOrderType(_.get(orderConfig, 'prctyp', '')),
    instrument_token: symbolId,
    quantity: _.get(orderConfig, 'qty', 0),
    disclosed_quantity: 0,
    price: _.get(orderConfig, 'price', 0),
    order_side: _.get(orderConfig, 'transactionType', '').toUpperCase(),
    trigger_price: _.get(orderConfig, 'trigPrice', 0),
    validity: _.get(orderConfig, 'ret', ''),
    product: parseProductCode(_.get(orderConfig, 'pCode', '')),
    client_id: brokerClientId,
    user_order_id: Math.floor(getCurrentDate().valueOf() / 1000),
    market_protection_percentage: 0,
    device: 'WEB',
    amo: !isCurrentTimeWithinTradingHours(),
  };
};

const parseOrderDetails = async (orders, type) => {
  let formattedData = [];
  const exchangeTokens = _.map(orders, (arr) => _.get(arr, 'instrument_token', '') || _.get(arr, 'token', ''));
  await parseInstrumentDetailsByExchangeTokens(exchangeTokens);

  if (_.isArray(orders)) {
    formattedData = _.map(orders, (orderDetail) => {
      switch (type) {
        case 'order':
          return parseOrderBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'holdings':
          return parseHoldingsBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

const parseModifyOrder = async (orderConfig, brokerClientId) => {
  const { tradingSymbolObj } = orderConfig;
  const exchange = tradingSymbolObj?.getExchange();
  const exchangeSegement =
    tradingSymbolObj?.isEquity() && tradingSymbolObj?.getExchange() === 'NSE'
      ? 'NSE'
      : tradingSymbolObj?.getExchange() === 'BSE'
        ? 'BFO'
        : 'NFO';
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol, exchange);
  return {
    exchange: exchangeSegement,
    instrument_token: symbolId,
    client_id: brokerClientId,
    order_type: parseOrderType(_.get(orderConfig, 'prctyp', '')),
    price: _.get(orderConfig, 'price', ''),
    quantity: _.get(orderConfig, 'qty', ''),
    disclosed_quantity: _.get(orderConfig, 'disCloseQty', ''),
    validity: _.get(orderConfig, 'ret', ''),
    product: parseProductCode(_.get(orderConfig, 'pCode', '')),
    oms_order_id: _.get(orderConfig, 'orderNo', ''),
    trigger_price: _.get(orderConfig, 'trigPrice', ''),
    execution_type: 'REGULAR',
  };
};

const parseCancelOrder = (orderConfig, brokerClientId) => {
  const { orderDetails } = orderConfig;
  const exchange_order_id = _.get(
    orderDetails,
    'extraDetails.orderDetail.exchange_order_id',
    ''
  );
  const oms_order_id = _.get(
    orderDetails,
    'extraDetails.orderDetail.oms_order_id',
    ''
  );

  return {
    client_id: brokerClientId,
    exchange_order_id,
    execution_type: 'Regular',
    leg_order_indicator: 'ENTRY',
    oms_order_id,
  };
};

const parseBrokerageCharges = (orderDetail, brokerClientId, HDFCSKY_APP_ID) => {
  const { tradingSymbolObj } = orderDetail;
  const exchangeSegement = tradingSymbolObj.getExchangeSegment();
  return {
    'api_key': HDFCSKY_APP_ID,
    'buy-sell': _.get(orderDetail, 'transactionType', '') === 'buy' ? 'B' : 'S',
    'client-id': brokerClientId,
    'exchange': exchangeSegement,
    'price': 0.05,
    'product': parseProductCode(_.get(orderDetail, 'pCode', '')),
    'quantity': _.get(orderDetail, 'qty', 0),
    'trading-symbol': getPlaceOrderTradingSymbol(tradingSymbolObj),
  };
};

export {
  parseOrderDetails,
  parseTradeBook,
  parseOrderBook,
  parsePositionBook,
  parseHoldingsBook,
  parsePlaceOrder,
  parseModifyOrder,
  parseCancelOrder,
  parseBrokerageCharges,
};
