const getLabelConfigs = (isEquity) => {
  return {
    placeOrderLabelConfigs: {
      mis: 'INTRADAY',
      cnc: isEquity ? 'CNC' : 'CARRY FORWARD',
      nrml: 'CARRY FORWARD',
    }
  };
};

export { getLabelConfigs };
