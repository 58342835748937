import _ from 'lodash';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isValidSymbolForInsta,
  parseExchangeTokenTradingSymbol,
  parseInstrumentDetailsByExchangeTokens,
  parseTradingSymbolObjByExchangeToken,
} from '../utils';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import { getDefaultProductCode } from '../tradingSymbolParser';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import dayjs from 'dayjs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import { isCurrentTimeWithinTradingHours } from 'habitual-analytics/utils/datetime';

const defaultParseOrderType = (orderType) => {
  let type;
  switch (_.toLower(orderType)) {
    case 'limit':
    case 'lmt':
      type = 'L';
      break;
    case 'market':
    case 'mkt':
      type = 'MARKET';
      break;
    case 'sl':
      type = 'SL';
      break;
    case 'slm':
    case 'sl-m':
      type = 'SL-M';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pCode) => {
  // In any case the cover order slides in, should be converted to empty string
  switch (_.toLower(pCode)) {
    case 'i':
      return _.toUpper('intraday');
    case 'c':
      return _.toUpper('cnc');
    case 'm':
      return _.toUpper('margin');
    default:
      return '';
  }
};

const formatPlaceOrderProductCode = (pCode) => {
  switch (pCode) {
    case 'MIS':
      return 'I';
    case 'CNC':
    case 'C':
      return 'C';
    case 'NRML':
    case 'M':
      return 'M';
    default:
      break;
  }
};

const parseProductType = (pType) => {
  switch (_.toLower(pType)) {
    case 'mkt':
    case 'm':
      return 'MKT';
    case 'sl':
      return 'SL';
    case 'l':
      return 'LMT';
    case 'sl-m':
    case 'slm':
      return 'SLM';
    case 'lmt':
      return 'LIMIT';
    default:
      return '';
  }
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let placedOrderStatus = statusConfigs.placed.value;
  const status = _.get(orderDetail, 'status', '');

  switch (status) {
    case 'Traded':
      placedOrderStatus = statusConfigs.executed.value;
      break;
    case 'Cancelled':
      placedOrderStatus = statusConfigs.cancelled.value;
      break;
    case 'Rejected':
      placedOrderStatus = statusConfigs.failed.value;
      break;
    case 'Pending':
      placedOrderStatus = statusConfigs.pending.value;
      break;
    default:
      placedOrderStatus = '';
      break;
  }
  return placedOrderStatus;
};

const parsePlaceOrder = async (orderConfig) => {
  const { tradingSymbolObj } = orderConfig;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const symbolID = await getExchangeToken(formattedExchangeTokenTradingSymbol);

  return {
    txn_type: _.get(orderConfig, 'transactionType', '') === 'buy' ? 'B' : 'S',
    exchange: 'NSE',
    segment: tradingSymbolObj.isEquity() ? 'E' : 'D',
    product: formatPlaceOrderProductCode(_.get(orderConfig, 'pCode', '')),
    security_id: _.toString(symbolID),
    quantity: _.get(orderConfig, 'qty', '0'),
    price: _.get(orderConfig, 'price', '0'),
    validity: _.get(orderConfig, 'ret', ''),
    order_type: parseProductType(_.get(orderConfig, 'prctyp', '')),
    disc_quantity: '0',
    trigger_price: _.get(orderConfig, 'trigPrice', '0.00'),
    off_mkt_flag: !isCurrentTimeWithinTradingHours(),
    user_type: 'C',
    remark1: 'instaoptions',
    remark2: '',
    mkt_type: 'NL',
    settlor: '000000000000',
    group_id: '1',
    order_no: '0',
    serial_no: '1',
    leg_no: '1',
    algo_order_no: '0',
    profit_value: '',
    stoploss_value: '',
    trailing_gap: '0'
  };
};

const parseSubOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(orderDetail?.security_id);
  const pCode = parseProductCode(_.get(orderDetail, 'product', ''));

  if (
    !pCode ||
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.symbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const status = sanitizeAndParseOrderStatus(orderDetail);
  const tradedTime = _.get(orderDetail, 'order_date_time', '');
  const failedReason = tradingSymbolObj?.isEquity() ? _.get(orderDetail, 'reason_description', '') : _.get(orderDetail, 'group_id', '');
  const productType = parseProductType(_.get(orderDetail, 'order_type', ''));
  const productCode = pCode;
  const isCancellableOrder = getCancellableOrderStatus(status);
  const quantity = _.get(orderDetail, 'quantity', 0);
  const tradedQty = _.get(orderDetail, 'traded_qty', 0);
  const avgPrice = convertToNumber(_.get(orderDetail, 'avg_traded_price', 0));
  const price = convertToNumber(_.get(orderDetail, 'price', 0));
  const targetPrice = _.get(orderDetail, 'trigger_price', 0);
  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice.toFixed(2)
        : price.toFixed(2);

  return {
    tradingSymbolObj,
    time: dayjs(tradedTime)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'txn_type', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${Math.abs(tradedQty)} / ${Math.abs(quantity)}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'order_no', ''),
      orderDetail,
      defaultProductCode: getDefaultProductCode(_.get(orderDetail, 'product', ''), tradingSymbolObj),
      defaultProductType: defaultParseOrderType(_.get(orderDetail, 'order_type', '')),
    },
  };
};

const parseOrderBook = (orderDetails) => {
  const isArray = _.isArray(orderDetails);
  if (isArray) {
    return _.map(orderDetails, parseSubOrderBook);
  }
  return parseSubOrderBook(orderDetails);
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(orderDetail?.security_id);
  const pCode = parseProductCode(_.get(orderDetail, 'product_name', ''));

  if (
    !pCode ||
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.symbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const tradedTime = _.get(orderDetail, 'order_date_time', '');
  const productType = parseProductType(_.get(orderDetail, 'order_type', ''));
  const productCode = _.toUpper(pCode);
  const tradedQuantity = Number(_.get(orderDetail, 'trade_value', 0) / _.get(orderDetail, 'traded_price', 0));
  const quantity = Number(_.get(orderDetail, 'quantity', 0));

  return {
    tradingSymbolObj,
    time: dayjs(tradedTime)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'BuySell', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: statusConfigs.executed.label,
    extraDetails: {
      product: `${productCode} / ${productType}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'traded_price', 0)),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(orderDetail?.security_id);  
  const pCode = parseProductCode(_.get(orderDetail, 'product', ''));
  if (
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.symbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const ltp = _.get(orderDetail, 'ltp', 0);
  const qty = Number(_.get(orderDetail, 'net_qty'), 0);
  const buyAvgPrice = _.get(orderDetail, 'buy_avg', 0);
  const sellAvgPrice = _.get(orderDetail, 'sell_avg', 0);

  const realisedProfitLoss = _.get(
    orderDetail,
    'realised_profit',
    0
  );
  const unRealisedProfitLoss =
    qty === 0
      ? 0
      : qty > 0
        ? (ltp - buyAvgPrice) * qty
        : (ltp - sellAvgPrice) * qty;

  const buyAvg = _.round(convertToNumber(buyAvgPrice), 2);
  const sellAvg = _.round(convertToNumber(sellAvgPrice), 2);

  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const profitLoss = realisedProfitLoss + unRealisedProfitLoss;

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    ltp,
    profitLoss,
    symbol: tradingSymbolObj.toString(),
    extraDetails: {
      product: pCode,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'product', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : ''
    },
  };
};

const parsePositionBook = (orderDetails) => {
  const isArray = _.isArray(orderDetails);

  if (isArray) {
    return _.map(orderDetails, parseSubPositionBook);
  };

  return parseSubPositionBook(orderDetails);
};

const parseCancelOrder = ({ orderNo, orderDetails, tradingSymbolObj }) => {
  const { orderDetail } = orderDetails.extraDetails;
  const qty = _.get(orderDetails, 'extraDetails.qty', '').split('/')[1].trim();

  return {
    txn_type: _.get(orderDetails, 'type', '') === 'buy' ? 'B' : 'S',
    exchange: _.get(orderDetail, 'exchange', ''),
    segment: tradingSymbolObj?.isEquity() ? 'E' : 'D',
    product: _.get(orderDetail, 'product', ''),
    security_id: _.get(orderDetail, 'security_id', ''),
    quantity: qty,
    price: _.get(orderDetail, 'price', 0),
    validity: _.get(orderDetail, 'validity', ''),
    order_type: _.get(orderDetail, 'order_type', ''),
    'disc_quantity': _.get(orderDetail, 'disc_quantity', '0'),
    'trigger_price': _.get(orderDetail, 'trigger_price', '0'),
    'mkt_type': _.get(orderDetail, 'mkt_type', 'NL'),
    'off_mkt_flag': !isCurrentTimeWithinTradingHours(),
    'user_type': 'C',
    'remark1': 'instaoptions',
    'remark2': '',
    'settlor': '000000000000',
    'group_id': '1',
    'order_no': orderNo,
    'serial_no': _.toString(_.get(orderDetail, 'serial_no', '1')),
    'leg_no': '1',
    'algo_order_no': '0',
    'profit_value': '',
    'stoploss_value': '',
    'trailing_gap': '0'
  };

};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'exchange', '');
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(orderDetail?.nse_security_id);

  if (
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.symbol) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  };

  const quantity = _.get(orderDetail, 'quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'cost_price', 0));
  const ltp = _.get(orderDetail, 'ltp', 0);
  const profitLoss = getRoundedData((ltp - orderValue) * quantity);
  const netChg = getRoundedData((profitLoss / orderValue) * 100);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbolObj?.toString(),
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${_.get(orderDetail, 'remaining_quantity', 0)})`,
      buyAverage: orderValue,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj?.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parseModifyOrder = async (orderConfig) => {
  const { extraDetails: { orderDetail } } = orderConfig;
  const { tradingSymbolObj } = orderConfig;
  const formattedExchangeTokenTradingSymbol =
    parseExchangeTokenTradingSymbol(tradingSymbolObj);

  const symbolId = await getExchangeToken(formattedExchangeTokenTradingSymbol);

  return {
    user_id: _.get(orderConfig, 'userId', ''),
    txn_type: _.get(orderDetail, 'txn_type', ''),
    exchange: _.get(orderDetail, 'exchange', ''),
    segment: _.get(orderDetail, 'segment', ''),
    product: _.get(orderDetail, 'product', ''),
    security_id: _.toString(symbolId),
    quantity: _.toString(_.get(orderConfig, 'qty', '0')),
    price: _.get(orderConfig, 'price', '0'),
    validity: _.get(orderConfig, 'ret', ''),
    order_type: parseProductType(_.get(orderConfig, 'prctyp', '')),
    disc_quantity: _.get(orderConfig, 'disCloseQty', '0'),
    trigger_price: _.toString(_.get(orderConfig, 'trigPrice', '0.00')),
    order_no: _.get(orderConfig, 'orderNo', '0'),
    off_mkt_flag: !isCurrentTimeWithinTradingHours(),
    user_type: 'C',
    remark1: 'instaoptions',
    remark2: '',
    mkt_type: _.toString(_.get(orderDetail, 'mkt_type', 'NL')),
    settlor: '000000000000',
    group_id: '1',
    serial_no: _.toString(_.get(orderDetail, 'serial_no', '1')),
    leg_no: '1',
    algo_order_no: '0',
    profit_value: '',
    stoploss_value: '',
    trailing_gap: '0',
  };
};

const parseOrderDetails = async (orderDetails, type) => {
  const exchangeTokens =
    _.map(orderDetails, (order) => _.get(order, 'security_id', '') || _.get(order, 'nse_security_id', ''));
  await parseInstrumentDetailsByExchangeTokens(exchangeTokens);

  let formattedData = [];
  if (_.isArray(orderDetails)) {
    formattedData = _.map(orderDetails, (orderDetail, index) => {
      switch (type) {
        case 'holdings':
          return parseHoldingsBook(orderDetail, index);
        case 'orders':
          return parseOrderBook(orderDetail);
        case 'position':
          return parsePositionBook(orderDetail);
        case 'trade':
          return parseTradeBook(orderDetail);
        default:
          return [];
      }
    });
  }
  return formattedData;
};

export {
  parsePlaceOrder,
  parseOrderBook,
  parsePositionBook,
  parseTradeBook,
  parseHoldingsBook,
  parseOrderDetails,
  parseCancelOrder,
  parseModifyOrder,
};
