import React from 'react';
import PropTypes from 'prop-types';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';

const propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  alt: PropTypes.string.isRequired
};

const defaultProps = {
  className: ''
};

const Image = (props) => {
  const {
    src, className, alt, ...restProps
  } = props;

  const { IMAGE_VERSION = 1 } = getDynamicAppConfigs()?.envs; 
  const querySeparator = _.includes(src, '?') ? '&' : '?';

  return (
    <img
      src={`${src}${querySeparator}imageVersion=${IMAGE_VERSION}`}
      className={className}
      alt={alt}
      {...restProps}
    />
  );
};

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
