import { statusConfigs, productCode, productType, initialProductType } from 'habitual-analytics/constants/habitual-configs';
import moment from 'moment';
import { getDynamicAppConfigs } from 'habitual-analytics/constants/dynamicAppConfigs';

const statusMap = {
  success: statusConfigs.executed.value,
  traded: statusConfigs.executed.value,
  failed: statusConfigs.failed.value,
  cancelled: statusConfigs.cancelled.value,
};

export const sanitizeAndParseOrderStatus = (sts) => {
  const status = sts.toLowerCase();
  return statusMap[status] || statusConfigs.pending.value;
};

export const sanitizeParseOrderType = (orderType) => {
  return productType[orderType] || productType.MARKET;
};

export const sanitizeDefaultOrderType = (orderType) => {
  return initialProductType[orderType] || initialProductType.MARKET;
};

export const sanitizeProductType = (rawProductCode, tradingSymbolObj) => {

  if (tradingSymbolObj?.isEquity() && rawProductCode === productCode.NRML) return productCode.CNC;
  return productCode[rawProductCode] || productCode.MIS;
};

export const formatUTCToIST = (utcTime) => {
  const { envs: { IS_DEV_MODE } } = getDynamicAppConfigs();
  // TODO:Get common format from backend
  return IS_DEV_MODE === 'true' ? moment(utcTime).add(5, 'hours').add(30, 'minutes').format('YYYY-MMM-DD HH:mm:ss')
    : moment(utcTime).format('YYYY-MMM-DD HH:mm:ss');
};
