import Instrument from 'habitual-analytics/models/instrument';
import _ from 'lodash';
import moment from 'moment';

export const sanitizePlaceOrdersBeforeMarginCalculation = (orders) => {
  return _.map(orders, (order) => {
    const { prctyp, price, trigPrice } = order;
    return {
      ...order,
      price: prctyp === 'MKT' || prctyp === 'SL-M' ? 0 : price,
      trigPrice: prctyp === 'MKT' || prctyp === 'LMT' ? 0 : trigPrice,
    };
  });
};

export const parseTradingSymbol = (orderDetail) => {
  if (_.get(orderDetail, 'series', '') === 'EQ') {
    return new Instrument({
      symbol: _.trimEnd(_.get(orderDetail, 'symbol', ''), 'EQ'),
      expiryDate: undefined,
      strikePrice: undefined,
      optionType: undefined,
    });
  }
  return new Instrument({
    symbol: _.get(orderDetail, 'symbol', ''),
    expiryDate: moment(_.get(orderDetail, 'expiryDate', undefined), 'DD MMM YYYY').format('YY-MM-DD'),
    strikePrice: _.get(orderDetail, 'strikePrice', undefined),
    optionType: _.get(orderDetail, 'optionType', undefined),
  });
};
