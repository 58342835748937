// Instrument tradingSymbolObj
export const formatTradingSymbol = (instrument) => {
  const exchange = instrument.getExchangeSegment();
  let facadeTradingSymbolObject = {
    exchange,
    instrument: _.get(instrument, 'symbol', ''),
    segment: instrument.getInstrumentType(),
  };
  if (!instrument.isEquity()) {
    const [year, month, date] = _.get(instrument, 'expiryDate', '').split('-');

    Object.assign(facadeTradingSymbolObject, {
      expiry: {
        year: Number(year?.slice(-2)) || '',
        month: Number(month) || '',
        date: Number(date) || '',
      },
      strikePrice: _.get(instrument, 'strikePrice', ''),
      optionType: _.get(instrument, 'optionType', ''),
    });
  }

  return facadeTradingSymbolObject;
};