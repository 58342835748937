import moment from 'moment';
import _ from 'lodash';
import {
  PRETTIER_FORMAT_WITH_SECONDS,
  TIME_FORMAT,
} from 'habitual-analytics/dateUtils/dateFormats';
import {
  statusConfigs,
  transactionTypes,
} from 'habitual-analytics/constants/habitual-configs';
import MarketUtility from 'habitual-analytics/utils/marketUtility';
import {
  convertToNumber,
  getCancellableOrderStatus,
  isValidSymbolForInsta,
  parseExchangeTokenTradingSymbol,
  parseInstrumentDetailsByExchangeTokens,
  parseTradingSymbolObjByExchangeToken,
} from '../utils';
import { getRoundedData } from 'habitual-analytics/common/formatter/number';
import { getFormattedMoney } from 'habitual-analytics/utils/money';
import { getDefaultProductCode } from '../tradingSymbolParser';
import { getExchangeToken } from 'habitual-analytics/api/services/getExchangeToken';
import {
  getDynamicAppConfigs,
} from 'habitual-analytics/constants/dynamicAppConfigs';
import dayjs from 'dayjs';
import { getCurrentDate } from 'habitual-analytics/utils/datetime';
import { WATCHLIST_DISPLAY_FORMAT } from 'habitual-analytics/dateUtils/dateFormats';
import { getParsedDateObj } from 'habitual-analytics/utils/timezone';

const getPayloadHeaders = () => {
  const { envs, brokerConfigs } = getDynamicAppConfigs();
  const { IIFL_USER_KEY } = envs;
  const { brokerClientAccessToken } = brokerConfigs;
  return {
    UserKey: IIFL_USER_KEY,
    JWTToken: brokerClientAccessToken,
  };
};

const parseProductType = (orderDetail) => {
  const atMarket = _.get(orderDetail, 'AtMarket', '') === 'Y';
  const withSL = _.get(orderDetail, 'WithSL', '') === 'Y';
  let productType;
  switch (true) {
    case atMarket:
      productType = 'MKT';
      break;
    case withSL:
      productType = 'SL';
      break;
    default:
      productType = 'LIMIT';
      break;
  }
  return productType;
};

const defaultProductType = (productType) => {
  let type;
  switch (_.trim(_.toLower(productType))) {
    case 'limit':
      type = 'L';
      break;
    case 'mkt':
      type = 'MKT';
      break;
    case 'sl-mkt':
      type = 'SL-M';
      break;
    case 'sl':
      type = 'SL';
      break;
    default:
      type = '';
  }
  return type;
};

const parseProductCode = (pCode) => {
  let productCode;
  switch (_.toLower(pCode)) {
    case 'i':
      productCode = _.toUpper('intraday');
      break;
    case 'd':
      productCode = _.toUpper('delivery');
      break;
    default:
      productCode = '';
      break;
  }
  return productCode;
};

const sanitizeAndParseOrderStatus = (orderDetail) => {
  let placedOrderStatus = statusConfigs.placed.value;
  const status = _.trim(_.get(orderDetail, 'OrderStatus', ''));

  switch (status) {
    case 'Fully Executed':
      placedOrderStatus = statusConfigs.executed.value;
      break;
    case 'Cancelled':
      placedOrderStatus = statusConfigs.cancelled.value;
      break;
    case 'Pending':
      placedOrderStatus = statusConfigs.pending.value;
      break;
    case 'Rejected By IIFL':
    case 'Rejected by Exch':
      placedOrderStatus = statusConfigs.failed.value;
      break;
    default:
      placedOrderStatus = '';
      break;
  }
  return placedOrderStatus;
};

const getFormattedTime = (time) => {
  const regex = /(\d+)/;
  const match = regex.exec(time);
  const timestamp = parseInt(match[1]);

  return moment(timestamp).utcOffset('+0530').format('HH:mm:ss');
};

const formattedTime = (time) => {
  let cleanedStr = time.replace('/Date(', '').replace(')', '');
  let parts = cleanedStr.split('+');
  let timestamp = parts[0];
  return _.parseInt(timestamp);
};

const parsePlaceOrder = async (orderConfig, brokerExtras) => {
  const tradingSymbolObj = _.get(orderConfig, 'tradingSymbolObj', '');
  const exchangeSegment = tradingSymbolObj.getExchange();
  const { symbol, expiryDate, strikePrice, optionType } = tradingSymbolObj;
  const orderType =
    _.get(orderConfig, 'transactionType', '') === 'buy' ? 'B' : 'S';
  let targetTime = tradingSymbolObj.isEquity()
    ? getCurrentDate().valueOf()
    : getParsedDateObj(`${expiryDate} ${exchangeSegment === 'NSE' ? '14:30' : '00.00'}`).valueOf();
  targetTime += 5 * 60 * 60 * 1000;
  targetTime += 30 * 60 * 1000;
  const expiryTime = Math.floor(targetTime / 1000);
  const validTillDate = expiryTime - 315532800;
  const formateDate = moment(expiryDate).format(WATCHLIST_DISPLAY_FORMAT);

  const tradingSymbolParser = (tradingSymbolObj) => {
    if (tradingSymbolObj.isEquity()) {
      return `${tradingSymbolObj.toString()}_EQ`;
    } else if (tradingSymbolObj.isFuture()) {
      return `${symbol} ${formateDate}_${validTillDate}_XX_00`;
    } else {
      return `${symbol} ${formateDate} ${optionType} ${strikePrice}.00_${validTillDate}
        _${optionType}_${strikePrice}`;
    }
  };

  const exchange = tradingSymbolObj.getExchange() === 'NSE' ? 'N' : 'B';

  const payloadData = {
    head: getPayloadHeaders(),
    body: {
      ClientCode: _.get(orderConfig, 'userId', ''),
      Price: _.get(orderConfig, 'price', 0),
      UniqueOrderID: 'NCREL123',
      OrderType: orderType,
      Qty: _.get(orderConfig, 'qty', 0),
      Exchange: exchange,
      ExchangeType: tradingSymbolObj.isEquity() ? 'C' : 'D',
      NmSymbol: tradingSymbolParser(tradingSymbolObj),
      DisQty: 0,
      StopLossPrice: _.get(orderConfig, 'trigPrice', 0),
      IOCOrder: false,
      IsIntraday: _.get(orderConfig, 'pCode', '') === 'MIS',
      AHPlaced: 'N',
      ValidTillDate: `/Date(${validTillDate})/`,
    },
  };

  return {
    p_Data: payloadData,
    AppSource: _.get(brokerExtras, 'appSource', 0),
  };
};

const parseCancelOrder = (orderConfig, brokerClientId, brokerExtras) => {
  const { orderDetails } = orderConfig;
  const tradingSymbolObj = _.get(orderConfig, 'tradingSymbolObj', '');
  const exchangeOrderId = _.get(orderDetails, 'extraDetails.orderDetails.ExchOrderID', '');
  const exchange = tradingSymbolObj.getExchange() === 'NSE' ? 'N' : 'B';

  const requestBody = {
    ClientCode: brokerClientId,
    ExchOrderID: exchangeOrderId,
    Exchange: exchange,
    ExchangeType: tradingSymbolObj.isEquity() ? 'C' : 'D',
  };

  const payloadData = {
    head: getPayloadHeaders(),
    body: requestBody,
  };
  return {
    p_Data: payloadData,
    AppSource: _.get(brokerExtras, 'appSource', 0),
  };
};

const parseMarginCalculator = async (orderConfig) => {
  const { brokerConfigs } = getDynamicAppConfigs();
  const { brokerClientId } = brokerConfigs;

  const tradingSymbolObj = _.get(orderConfig, 'tradingSymbolObj', '');
  const formattedTradingSymbol = parseExchangeTokenTradingSymbol(tradingSymbolObj);
  const exchangeSegment = tradingSymbolObj.getExchange();
  const exchangeToken = await getExchangeToken(formattedTradingSymbol, exchangeSegment);
  const exchange = tradingSymbolObj.getExchange() === 'NSE' ? 'N' : 'B';

  const requestHeaders = getPayloadHeaders();
  const reqBody = {
    ClientCode: brokerClientId,
    Exchange: exchange,
    ExchangeType: tradingSymbolObj.isEquity() ? 'C' : 'D',
    BuySell: _.get(orderConfig, 'transactionType', '') === 'buy' ? 'B' : 'S',
    ScripCode: exchangeToken,
    Product: _.get(orderConfig, 'pCode', '') === 'MIS' ? 'I' : 'D',
    OrderQuantity: _.get(orderConfig, 'qty', 0),
    OrderPrice: _.get(orderConfig, 'price', 0),
    ExchangeOrderId: 0,
  };
  const payloadData = {
    head: requestHeaders,
    body: reqBody,
  };
  return { ...payloadData };
};

const parseOrderBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'Exch', '') === 'N' ? 'NSE' : 'BSE';
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(_.get(orderDetail, 'ScripCode', ''));

  if (
    !tradingSymbolObj ||
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const status = sanitizeAndParseOrderStatus(orderDetail);
  const failedReason = _.get(orderDetail, 'Reason', '');
  const productType = parseProductType(orderDetail);
  const productCode = parseProductCode(_.get(orderDetail, 'DelvIntra', ''));
  const isCancellableOrder = getCancellableOrderStatus(status);
  const quantity = _.get(orderDetail, 'Qty', 0);
  const tradedQty = _.get(orderDetail, 'TradedQty', 0);
  const price = convertToNumber(_.get(orderDetail, 'Rate', 0));
  const targetPrice = _.get(orderDetail, 'SLTriggerRate', 0);
  const avgPrice = convertToNumber((price * quantity) / quantity);

  const tradedPrice =
    _.parseInt(targetPrice) !== 0
      ? `${price} / ${targetPrice} trg`
      : _.parseInt(avgPrice) !== 0
        ? avgPrice?.toFixed(2)
        : price?.toFixed(2);
  const tradedTime = formattedTime(_.get(orderDetail, 'BrokerOrderTime', ''));

  return {
    tradingSymbolObj,
    time: dayjs(tradedTime)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'BuySell', '') === 'S'
        ? transactionTypes?.sell.value
        : transactionTypes?.buy?.value,
    status: isCancellableOrder ? statusConfigs.pending.value : status,
    isCancellableOrder,
    failedReason,
    BrokerOrderId: _.get(orderDetail, 'BrokerOrderId', ''),
    ExchOrderID: _.toNumber(_.get(orderDetail, 'ExchOrderID', '')),
    extraDetails: {
      orderDetails: orderDetail,
      product: `${productCode} / ${productType}`,
      qty: `${Math.abs(tradedQty)} / ${Math.abs(quantity)}`,
      tradedPrice,
      orderNo: _.get(orderDetail, 'ExchOrderID', ''),
      exchOrderID: _.toNumber(_.get(orderDetail, 'BrokerOrderId', '')),
      defaultProductCode: getDefaultProductCode(productCode, tradingSymbolObj),
      defaultProductType: defaultProductType(productType),
    },
  };
};

const parseSubPositionBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'Exch', '') === 'N' ? 'NSE' : 'BSE';
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(_.get(orderDetail, 'ScripCode', ''));

  if (
    !tradingSymbolObj ||
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const BODQty = Number(_.get(orderDetail, 'BodQty', 0));
  const BODPositionPrice = Number(_.get(orderDetail, 'BODPositionPrice', 0));
  const sellValue = Number(_.get(orderDetail, 'SellValue', 0));
  const sellQty = Number(_.get(orderDetail, 'SellQty', 0));
  const buyValue = Number(_.get(orderDetail, 'BuyValue', 0));
  const buyQty = Number(_.get(orderDetail, 'BuyQty', 0));

  const ltp = _.get(orderDetail, 'ltp');
  const qty = Number(_.get(orderDetail, 'NetQty', 0));

  const buyAvg = _.round(
    (_.max([0, BODQty]) * BODPositionPrice + buyValue) / (_.max([0, BODQty]) + buyQty),
    2) || 0;

  const sellAvg = _.round(
    (_.max([0, -1 * BODQty]) * BODPositionPrice + sellValue) / (_.max([0, -1 * BODQty]) + sellQty),
    2) || 0;

  const realisedprofitloss = _.round(_.get(orderDetail, 'BookedPL', 0), 2);
  const avgRate = qty > 0 ? buyAvg : sellAvg;
  const unRealisedProfitLoss =
    qty === 0
      ? 0
      : qty > 0
        ? (ltp - buyAvg) * qty
        : (ltp - sellAvg) * qty;

  const type =
    Number(qty) < 0
      ? transactionTypes?.sell?.value
      : transactionTypes?.buy?.value;
  const profitLoss = _.round(qty === 0 ? realisedprofitloss : unRealisedProfitLoss, 2);

  return {
    ...orderDetail,
    tradingSymbolObj,
    qty,
    buyAvg,
    sellAvg,
    avgRate,
    ltp,
    profitLoss,
    extraDetails: {
      product: parseProductCode(_.get(orderDetail, 'OrderFor', '')),
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      defaultProductCode: getDefaultProductCode(
        _.get(orderDetail, 'OrderFor', ''),
        tradingSymbolObj
      ),
      order: orderDetail,
      isOpenPosition: qty !== 0,
      type: qty !== 0 ? type : '',
    },
  };
};

const parsePositionBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubPositionBook);
  }
  return parseSubPositionBook(orderDetail);
};

const parseTradeBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'Exch', '') === 'N' ? 'NSE' : 'BSE';
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(_.get(orderDetail, 'ScripCode', ''));

  if (
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }

  const productCode = parseProductCode(_.get(orderDetail, 'DelvIntra', ''));
  const tradedQuantity = Number(_.get(orderDetail, 'OrgQty', 0));
  const quantity = Number(_.get(orderDetail, 'Qty', 0));
  const status = statusConfigs.executed.label;
  const tradedTime = getFormattedTime(
    _.get(orderDetail, 'ExchangeTradeTime', '')
  );

  return {
    tradingSymbolObj,
    time: moment(tradedTime, TIME_FORMAT)?.format(PRETTIER_FORMAT_WITH_SECONDS),
    type:
      _.get(orderDetail, 'BuySell', '') === 'B'
        ? transactionTypes.buy.value
        : transactionTypes.sell.value,
    status,
    extraDetails: {
      product: `${productCode}`,
      qty: `${tradedQuantity} / ${quantity}`,
      tradedPrice: convertToNumber(_.get(orderDetail, 'Rate', 0)),
    },
  };
};

const parseSubHoldingBook = (orderDetail) => {
  const exchange = _.get(orderDetail, 'Exch', '') === 'N' ? 'NSE' : 'BSE';
  const tradingSymbolObj = parseTradingSymbolObjByExchangeToken(_.get(orderDetail, 'NseCode', ''));

  if (
    !tradingSymbolObj ||
    !isValidSymbolForInsta(exchange, tradingSymbolObj?.toString()) ||
    !_.includes(MarketUtility.getSymbols(), tradingSymbolObj?.symbol)
  ) {
    return null;
  }
  const quantity = _.get(orderDetail, 'Quantity', 0);
  const orderValue = convertToNumber(_.get(orderDetail, 'CurrentValue', 0));
  const buyAvg = convertToNumber(orderValue / quantity);
  const ltp = convertToNumber(_.get(orderDetail, 'ltp', 0));
  const profitLoss = getRoundedData((ltp - buyAvg) * quantity);
  const netChg = getRoundedData((profitLoss / orderValue) * 100);

  return {
    tradingSymbolObj,
    ltp,
    Nsetsym: tradingSymbolObj?.toString(),
    profitLoss,
    extraDetails: {
      quantity: `${quantity} (T1:${quantity})`,
      buyAverage: orderValue / quantity,
      buyValue: orderValue,
      netChg: `${getFormattedMoney(netChg)}%`,
      liveUpdateDetails: {
        symbol: tradingSymbolObj.toString(),
        value: 'ltp',
        key: 'ltp',
      },
      order: orderDetail,
    },
  };
};

const parseHoldingsBook = (orderDetail) => {
  const isArrayDetails = _.isArray(orderDetail);
  if (isArrayDetails) {
    return _.map(orderDetail, parseSubHoldingBook);
  }

  return parseSubHoldingBook(orderDetail);
};

const parseModifyOrder = (orderConfig, brokerClientId) => {
  const { brokerExtras } = getDynamicAppConfigs().brokerConfigs;

  const tradingSymbolObj = _.get(orderConfig, 'tradingSymbolObj', '');
  const { symbol, expiryDate, strikePrice, optionType } = tradingSymbolObj;
  const exchangeOrderId = _.get(orderConfig, 'extraDetails.orderDetails.ExchOrderID', 0);
  const exchangeSegment = tradingSymbolObj.getExchange();
  const orderType = _.get(orderConfig, 'transactionType', '') === 'buy' ? 'BUY' : 'SELL';
  let targetTime = tradingSymbolObj.isEquity()
    ? getCurrentDate().valueOf()
    : getParsedDateObj(`${expiryDate} ${exchangeSegment === 'NSE' ? '14:30' : '00.00'}`).valueOf();
  targetTime += 5 * 60 * 60 * 1000;
  targetTime += 30 * 60 * 1000;
  const expiryTime = Math.floor(targetTime / 1000);
  const validTillDate = expiryTime - 315532800;
  const formateDate = moment(expiryDate).format(WATCHLIST_DISPLAY_FORMAT);

  const tradingSymbolParser = (tradingSymbolObj) => {
    if (tradingSymbolObj.isEquity()) {
      return `${tradingSymbolObj.toString()}_EQ`;
    } else if (tradingSymbolObj.isFuture()) {
      return `${symbol} ${formateDate}_${validTillDate}_XX_00`;
    } else {
      return (
        `${symbol} ${formateDate} ${optionType} ${strikePrice}.00_${validTillDate}
          _${optionType}_${strikePrice}`);
    }
  };

  const exchange = tradingSymbolObj.getExchange() === 'NSE' ? 'N' : 'B';


  const requestBody = {
    ClientCode: brokerClientId,
    ExchOrderID: exchangeOrderId,
    Price: _.get(orderConfig, 'price', 0),
    UniqueOrderID: 'NCREL123',
    OrderType: orderType,
    Qty: _.get(orderConfig, 'qty', 0),
    Exchange: exchange,
    ExchangeType: tradingSymbolObj.isEquity() ? 'C' : 'D',
    NmSymbol: tradingSymbolParser(tradingSymbolObj),
    AtMarket: true,
    DisQty: 0,
    IsStopLossOrder: false,
    StopLossPrice: _.get(orderConfig, 'trigPrice', 0),
    IOCOrder: false,
    IsIntraday: _.get(orderConfig, 'pCode', '') === 'MIS',
    AHPlaced: 'N',
    ValidTillDate: `/Date(${validTillDate})/`,
  };

  const payloadData = {
    head: getPayloadHeaders(),
    body: requestBody,
  };

  return {
    p_Data: payloadData,
    AppSource: _.get(brokerExtras, 'appSource', 0),
  };
};

const parseOrderDetails = async (orderDetails, type) => {
  const exchangeTokens = _.map(orderDetails, (orderDetail) =>
    _.get(orderDetail, 'ScripCode', '') || _.get(orderDetail, 'NseCode', ''));
  
  await parseInstrumentDetailsByExchangeTokens(exchangeTokens);

  let formattedData = [];
  if (_.isArray(orderDetails)) {
    formattedData = await Promise.all(
      _.map(orderDetails, async (orderDetail) => {
        switch (type) {
          case 'order':
            return parseOrderBook(orderDetail);
          case 'trade':
            return parseTradeBook(orderDetail);
          case 'position':
            return parsePositionBook(orderDetail);
          case 'holdings':
            return parseHoldingsBook(orderDetail);
          default:
            return [];
        }
      })
    );
  }
  return formattedData;
};

export {
  parsePlaceOrder,
  parseCancelOrder,
  parseModifyOrder,
  parseMarginCalculator,
  parseOrderBook,
  parsePositionBook,
  parseTradeBook,
  parseHoldingsBook,
  parseOrderDetails,
};
